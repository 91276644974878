import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n/src';
import QuoteMark from '../../../../Atoms/QuoteMark/QuoteMark';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Carousel from '../../../../components/Carousel/Carousel';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import Seo from '../../../../components/Seo/Seo';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import useMedias from '../../../../hooks/useMedias';
import Layout from '../../../../Layout';
import { Button } from '../../../../Molecules/Button/Button';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import VideoSection from '../../../../components/ContentType/Section/VideoSection/VideoSection';
import PopinVideo from '../../../../components/PopinVideo/PopinVideo';
import {
  removeHtmlTag,
  getBreadCrumb,
  getReOrderedCarouselSlides,
  youtube_parser
} from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const RealisationPage = ({ data }) => {
  const intl = useIntl();
  const { processUrl, getImage } = useMedias();

  const pageData = data?.pageData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const metaTags =  pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });

  const [allText, setAllText] = useState('');
  const [videoId, setVideoId] = useState('');
  const [stopVideo, setStopVideo] = useState(false);


  let globalText = '';
  let sliders = [];
  let sectionCount = 0;

  const slider_settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1.5, //not on desktop
    speed: 500,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    setAllText(
      removeHtmlTag(
        pageData.body?.processed ||
        '' + '.' + pageData.field_text_2?.processed ||
        '' + globalText ||
        ''
      )
    );
  }, []);


  const loadVideo = (val) => {
    setVideoId(val);
    setStopVideo(false);
    document.body.classList.add('show_video_popin');
  }

  const closePopinVideo = () => {
    document.body.classList.remove('show_video_popin');
    setStopVideo(true);
    if (document.querySelector('a.accessibilite')) {
      document.querySelector('a.accessibilite').focus();
    }
  }

  const mobileClickHandler = (e) => {
    e.preventDefault();
    loadVideo(youtube_parser(e.target.getAttribute('videoUrl')))
  }


  return (
    <>
      <PopinVideo videoId={videoId} closePopinVideo={closePopinVideo} stopVideo={stopVideo} />

      <Layout noFollow={pageData?.field_exclude}>
        <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
        />
        <div className={classNames('page_template', 'activities_inner_page')}>
          <PageBannerStyle
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            scroll={true}
            breadcrumbData={{
              grandparentPage: {
                title: breadCrumb?.grandParent?.name,
                url: breadCrumb?.grandParent?.link,
              },
              gparent: {
                title: breadCrumb?.gGrandParent?.name,
                url: breadCrumb?.gGrandParent?.link,
              },
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: breadCrumb?.current?.name,
                url: breadCrumb?.current?.link,
              },
              locale: pageData.langcode,
            }}
          >
            <div className="wrapper_page">
              <TitlePage color="color_white" title={pageData.title} />

              <div className="col_2_fix">
                <div className="col">
                  <div
                    dangerouslySetInnerHTML={{ __html: pageData.body?.processed }}
                  ></div>
                </div>
                <div className="col">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.field_text_2?.processed,
                    }}
                  ></div>
                </div>
              </div>
              {allText && allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                />
              )}
            </div>
          </PageBannerStyle>

          {pageData.relationships?.field_blocs?.map((block, i) => {
            switch (block.__typename) {
              case 'block_content__basic':
                globalText +=
                  block.field_title?.processed + '.' + block?.body?.processed;
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'section_diverse_activities'
                    )}
                    key={i}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection title={block.field_title?.processed} />

                      <TextVisuelTwoCol
                        orderInverse={
                          block.field_alignment === 'right' ? true : false
                        }
                        extremeRight={
                          block.field_alignment === 'right' ? true : false
                        }
                        extremeLeft={
                          block.field_alignment === 'left' ? true : false
                        }
                        alignLeft={false}
                        alignTop={false}
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image?.drupal_internal__mid
                        )}
                        text={block?.body?.processed}
                      />
                    </div>
                  </section>
                );
              case 'block_content__block_citation':
                globalText += block?.field_citation + '.';
                return (
                  <section className={classNames('section_content')} key={i}>
                    <div className="quotemark_container">
                      <QuoteMark
                        lang={block.langcode}
                        size="m"
                        textWeight="m"
                        text={block?.field_citation}
                      />
                    </div>
                  </section>
                );

              case 'block_content__block_slider_innovation':
                sliders = [];
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                block.relationships?.field_slides?.forEach((item) => {
                  sliders.push({
                    image: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    info: {
                      text: item.field_title?.processed,
                      link: block.field_link?.url,
                      link_label: block.field_link?.title,
                      anchor:
                        '?anchor=section-' +
                        item?.relationships?.field_anchor?.drupal_internal__tid,
                    },
                  });
                });
                sliders.push(...sliders);

                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_1_section',
                      'scroll_to_' + sectionCount
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={block.field_border_top && 'left'}
                      color={
                        block.field_backgroud_color === 'ice'
                          ? 'catskill_white'
                          : block.field_backgroud_color === 'navy'
                            ? 'dark_bleu'
                            : 'white'
                      }
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          type="arrow"
                          h2color={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'white'
                                : ''
                          }
                          h4color={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'white'
                                : ''
                          }
                          className={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'color_white'
                                : ''
                          }
                        />
                        <div
                          className={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'color_white'
                                : ''
                          }
                          dangerouslySetInnerHTML={{
                            __html: block.body?.processed,
                          }}
                        />
                      </div>
                      <div className={classNames('only_mobile_tablet')}>
                        <SliderComponent settings={slider_settings}>
                          {sliders.map((slide, j) => (
                            <div key={j}>
                              {slide?.image && (
                                <img
                                  src={processUrl(slide.image.uri.url)}
                                  alt={`slide-${i}-${j}`}
                                  style={{
                                    width: '100%',
                                    height: '123vw',
                                    maxHeight: '510px',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}
                              <div className="info_title_description">
                                <p>{slide.info.text}</p>
                                {slide.info.link_label && slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                      <div className="wrapper_page_xs">
                        <div className={classNames('only_desktop')}>
                          <Carousel
                            slides={getReOrderedCarouselSlides(sliders)}
                            sliderMode=""
                            textColor={
                              block.field_backgroud_color === 'ice'
                                ? ''
                                : block.field_backgroud_color === 'navy'
                                  ? 'color_white'
                                  : ''
                            }
                          />
                        </div>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_slider_nos_activites':
                let slides = [];
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                block.relationships?.field_nos_activites?.forEach((item) => {

                  slides.push({
                    image: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    info: {
                      text: item.title,
                      description: item.body?.summary,
                      link: block.field_link_title ? '/' + item.path?.langcode + item.path?.alias : item.field_link?.url,
                      videoUrl: item.field_link?.url,
                      link_label: block.field_link_title
                    },
                  });
                });

                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_2_section',
                      'scroll_to_' + sectionCount
                    )}
                  >

                    <ShapeBackground
                      top={
                        block.field_border_top &&
                          block.field_position_bordure_haut === 'gauche'
                          ? 'left'
                          : block.field_border_top &&
                          block.field_position_bordure_haut != null &&
                          'right'
                      }
                      color={
                        block.field_backgroud_color === 'ice'
                          ? 'catskill_white'
                          : block.field_backgroud_color === 'navy'
                            ? 'dark_bleu'
                            : 'white'
                      }
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className={classNames('wrapper_page_xs')}>
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          type="arrow"
                          h2color={block.field_backgroud_color === 'navy' ? 'white' : 'bleu'}
                          h4color={block.field_backgroud_color === 'navy' ? 'white' : 'bleu'}
                        />
                      </div>
                      <div
                        className={classNames('wrapper_page_xs', 'only_desktop')}
                      >
                        <Carousel
                          slides={getReOrderedCarouselSlides(slides)}
                          sliderMode="title_description"
                          openVideo={loadVideo}
                        />
                      </div>
                      <div className={classNames('only_mobile_tablet')}>
                        <SliderComponent settings={slider_settings}>
                          {slides.map((slide, index) => (
                            <div key={index}>
                              <div className="only_mobile">
                                <div className="image-wrapper">
                                  <img
                                    src={processUrl(slide.image.uri.url)}
                                    alt={`slide-${index}`}
                                    style={{
                                      width: '100%',
                                      height: '123vw',
                                      maxHeight: '510px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="only_tablet">
                                <img
                                  src={processUrl(slide.image.uri.url)}
                                  alt={`slide-${index}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                              <div className="info_title_description">
                                <p videoUrl={slide.info.link} onClick={slide.info.link ? mobileClickHandler : null}>{slide.info.text}</p>
                                {slide.info.link_label && slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                  //videoUrl={slide.info.link}
                                  //onClick={mobileClickHandler}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_video':
                const videoContent = {
                  content: [
                    {
                      type: 'video',
                      link: block.field_link?.uri,
                      title: removeHtmlTag(block.field_title?.processed),
                      titleArrow: 'mainYellow',
                      image: getImage(
                        imagesArray,
                        block.relationships?.field_image?.drupal_internal__mid
                      ),
                    },
                  ],
                };
                return <VideoSection videoDetails={videoContent}></VideoSection>;

            }
          })}
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query DetailRealisationTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    pageData: allNodeRealisations(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_exclude
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  body {
                    processed
                  }
                  field_alignment
                  field_title {
                    processed
                  }
                  field_no_padding
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_citation {
                  field_citation
                  langcode
                }
                ... on block_content__block_video {
                  field_title {
                    processed
                  }
                  field_link {
                    uri
                  }
                }
                ... on block_content__block_slider_innovation {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_slides {
                      field_title {
                        processed
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_anchor {
                          drupal_internal__tid
                        }
                      }
                    }
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom_display
                  field_link {
                    title
                    url
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_link_title
                  field_border_top
                  field_infinite_border
                  field_border_bottom
                  field_backgroud_color
                  field_position_bordure_haut
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__realisations {
                          title
                          body {
                            summary
                          }
                          field_link {
                            url
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                          path {
                            alias
                            langcode
                          }
                        }
                        ... on node__activites {
                          title
                          body {
                            summary
                          }
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                          field_text_2 {
                            processed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          field_text_2 {
            processed
          }
          body {
            processed
          }
          title
          langcode
          path {
            langcode
            alias
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default RealisationPage;
